import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { StopStatus } from 'harmony-constants';
import { L } from 'harmony-language';
import React from 'react';
import { useManuallyCompleteLoads } from '../../../api/mutations/use-manually-complete-loads';
import { Load, Stop } from '../../../types';
import { localNow } from '../../../utils/date-time-utils';
import { ManuallyCompleteOrderItem } from './manually-complete-order-item';

const setManuallyCompleteLoadsDefaults = (completableLoads: Load[]) => {
    const withDefaults = completableLoads.map(completableLoad => {
        return {
            ...completableLoad,
            stops: completableLoad.stops.map(stop => {
                const stopIsComplete = stop.status === StopStatus.Complete;

                if (stopIsComplete) {
                    return stop;
                } else {
                    return {
                        ...stop,
                        completedAt: localNow().seconds(0).milliseconds(0).toISOString(),
                        metadata: {
                            ...stop.metadata,
                            suppressDeliveryNotification: true,
                        },
                    }
                }
            }),
        }
    });

    return withDefaults;
}

interface ManuallyCompleteModalProps {
    completableLoads: Load[];
    onSubmit: () => void;
}

export const ManuallyCompleteModal: React.FC<ManuallyCompleteModalProps> = (props) => {
    const { completableLoads, onSubmit } = props;
    const [manuallyCompleteLoads, setManuallyCompleteLoads] = React.useState(setManuallyCompleteLoadsDefaults(completableLoads));
    const { mutate: manuallyComplete } = useManuallyCompleteLoads();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const loadsToComplete = manuallyCompleteLoads.map(x => ({
            id: x.id,
            stops: x.stops.map(s => ({
                id: s.id,
                metadata: s.metadata,
                completedAt: s.completedAt,
            }))
        }));
        manuallyComplete({ loads: loadsToComplete });
        onSubmit();
    };

    const handleChange = (stopId: number, updatedStop: Stop) => {
        const updatedLoads = manuallyCompleteLoads.map(load => {
            return {
                ...load,
                stops: load.stops.map(stop => {
                    if (stop.id === stopId) {
                        return updatedStop;
                    } else {
                        return stop
                    }
                })
            }
        });
        setManuallyCompleteLoads(updatedLoads);
    }

    if (manuallyCompleteLoads.length) {
        return (
            <>
                <form id={'manually-complete-loads'} onSubmit={(e) => handleSubmit(e)}>
                    <List>
                        {manuallyCompleteLoads.map((load, i) => {
                            return (
                                <ManuallyCompleteOrderItem key={i} order={load} handleChange={handleChange} />
                            )
                        })}
                    </List>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button type='submit'
                                title='Save'
                                variant='contained'
                                color='primary'>{manuallyCompleteLoads.length > 1 ? L.manuallyCompleteLoads() : L.manuallyCompleteLoad()}</Button>
                    </div>
                </form>
            </>
        );
    } else {
        return null;
    }
};

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Card, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { IncludedDates } from 'harmony-constants';
import { L } from 'harmony-language';
import React, { useContext } from 'react';
import { QueryKeys } from '../../../api/config';
import { AgisticsContext } from '../../../app/agistics-context';
import { DateFilter } from '../../../types';
import { getWorkingWeek, localNow } from '../../../utils/date-time-utils';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { AgisticsDateRange } from '../inputs/agistics-date-range';
import { AgisticsMultiSelectWithPrefs } from '../multi-select/agistics-multi-select-with-prefs';
import { useLoadOrderGroup } from './utils/use-load-order-group';

interface DateFilteringProps {
    onlyCustom?: boolean;
    tableKey: string;
    refetch: () => void;
}

export const DateFiltering = (props: DateFilteringProps) => {
    const { onlyCustom, tableKey, refetch } = props;
    const { dateFilters, updateDateFilters } = useContext(AgisticsContext);
    const dateFilter = dateFilters[tableKey];
    const orderGroup = useLoadOrderGroup();
    const { preferences } = useUpdateUserPreferences();
    const [includedDates, setIncludedDates] = React.useState(preferences.includedDates || []);
    
    const includedDatesVisable = !orderGroup.guid && tableKey !== QueryKeys.productionCardGroups;
    const includedDatesMultiItems = Object.values(IncludedDates)
        .map(x => ({ id: x.id, value: L[x.name]() }))
        //completed at date filter should only be present on loads screen
        .filter(x => tableKey === '/self/orders' || x.id !== 4);
    const dateDisplay = dateFilter.from === dateFilter.to
        ? dateFilter.from.slice(0, 10)
        : `${dateFilter.from} — ${dateFilter.to}`
    const dateLabel = dateFilter.mode === 'day'
        ? dateFilter.offset < -1
            ? `${Math.abs(dateFilter.offset)} ${L.daysAgo()}`
            : dateFilter.offset === -1
            ? L.yesterday()
            : dateFilter.offset === 0
            ? L.today()
            : dateFilter.offset === 1
            ? L.tomorrow()
            : `${dateFilter.offset} ${L.daysAhead()}`
        : dateFilter.offset < -1
            ? `${Math.abs(dateFilter.offset)} ${L.weeksAgo()}`
            : dateFilter.offset === -1
            ? L.lastWeek()
            : dateFilter.offset === 0
            ? L.thisWeek()
            : dateFilter.offset === 1
            ? L.nextWeek()
            : `${dateFilter.offset} ${L.weeksAhead()}`;
    
    function setDateFilter(dateFilter: Partial<DateFilter>) {
        orderGroup.remove();
        if (dateFilter?.mode !== 'custom') {
            dateFilter.includedDates = [];
        } else {
            // 1st load, need to get dates from preferences if exists
            if (!dateFilter.includedDates) {
                dateFilter.includedDates = preferences.includedDates || [];
            }
        }

        updateDateFilters(tableKey, dateFilter);
    }

    function handleDateOffset(incrementValue: number) {
        const newOffset = dateFilter.offset + incrementValue;

        setDateFilter({
            ...dateFilter,
            offset: newOffset,
            from: dateFilter.mode === 'week'
                ? getWorkingWeek(newOffset).sundayIso
                : localNow().startOf('day').add(newOffset, 'day').toISOString(),
            to: dateFilter.mode === 'week'
                ? getWorkingWeek(newOffset).saturdayIso
                : localNow().startOf('day').add(newOffset, 'day').toISOString()
        });
    }

    return (
        <Grid container spacing={2} alignItems='center' sx={{ minHeight: '64px' }}>
            {!onlyCustom &&
                <>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='inherit'
                            startIcon={<AutorenewIcon />}
                            onClick={refetch}>{L.refresh()}
                        </Button>
                    </Grid>

                    <Grid item>
                        <ToggleButtonGroup value={dateFilter.mode} fullWidth>
                            <ToggleButton
                                value={'day'}
                                onClick={() => {
                                    setDateFilter({
                                        mode: 'day',
                                        offset: 0,
                                        from: localNow().startOf('day').toISOString(),
                                        to: localNow().startOf('day').toISOString()
                                    });
                                }}
                            >
                                {L.day()}
                            </ToggleButton>

                            <ToggleButton
                                value={'week'}
                                onClick={() => {
                                    setDateFilter({
                                        mode: 'week',
                                        offset: 0,
                                        from: getWorkingWeek(0).sundayIso,
                                        to: getWorkingWeek(0).saturdayIso
                                    });
                                }}
                            >
                                {L.week()}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    
                    <Grid item>
                        <Stack direction='row' justifyContent='center'>
                            <Button
                                variant='contained'
                                color='inherit'
                                sx={{ padding: 0, minWidth: '32px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                onClick={() => handleDateOffset(-1)}
                            >
                                <ChevronLeft />
                            </Button>
                            <Card sx={{ minWidth: '13em', textAlign: 'center', borderRadius: 0, border: 1, borderColor: 'lightgray'}}>
                                <Typography>{dateDisplay}</Typography>
                                <Typography variant='body2'>{dateLabel}</Typography>
                            </Card>
                            <Button
                                variant='contained'
                                color='inherit'
                                sx={{ padding: 0, minWidth: '32px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={() => handleDateOffset(1)}
                            >
                                <ChevronRight />
                            </Button>
                        </Stack>
                    </Grid>
                    
                    <Grid item>
                        <Button
                            variant='contained'
                            color={dateFilter.mode === 'custom' ? 'secondary' : 'inherit'}
                            onClick={() => setDateFilter({ mode: 'custom' })}
                        >
                            {L.custom()}
                        </Button>
                    </Grid>
                </>
            }
            {dateFilter.mode === 'custom' &&
                <>
                    {includedDatesVisable && <Grid item xs={7} sm={6} md={4} lg={3} xl={2}>
                        <AgisticsMultiSelectWithPrefs
                            selectedIds={includedDates}
                            setSelectedIds={setIncludedDates}
                            items={includedDatesMultiItems}
                            label={L.includedDates()}
                            prefKey='includedDates'
                            removeMode={false}
                            additionalOnChange={(change) => {
                                setDateFilter({
                                    ...dateFilter,
                                    includedDates: change,
                                });
                            }}
                        />
                    </Grid>}
                    <Grid item>
                        <AgisticsDateRange
                            startDate={dateFilter.from}
                            endDate={dateFilter.to}
                            setStartDate={(date) => setDateFilter({ ...dateFilter, from: date })}
                            setEndDate={(date) => setDateFilter({ ...dateFilter, to: date })}
                        />
                    </Grid>
                </>
            }
        </Grid>
    );
};
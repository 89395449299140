import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys, QueryKeys } from '../../../api/config';
import { useAddAvoidanceZoneMutation } from '../../../api/mutations/add/use-add-avoidance-zone-mutation';
import { useEditAvoidanceZoneMutation } from '../../../api/mutations/edit/use-edit-avoidance-zone-mutation';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { useStaticQuery } from '../../../api/queries/use-static-query';
import { useUser } from '../../../api/queries/use-user';
import { useCompanyId } from '../../../app/agistics-context';
import { CREATE_AVOIDANCE_ZONES, EDIT_AVOIDANCE_ZONES, VIEW_ALL_COMPANIES } from '../../../permissions/permissions';
import '../../../scss/avoidance-zones.scss';
import { HereMap } from '../../here-maps/here-map';
import { HereMapRectangle } from '../../here-maps/here-map-rectangle';
import { StandardRouteLines } from '../../here-maps/standard-route-lines';
import { Content } from '../../shared/containers/content';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { SearchBar } from '../../shared/search-bar';
import { useFeatures, usePermissions } from '../../user/selectors/use-permissions';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import { AvoidanceZoneList } from './avoidance-zone-list';
import { EditCreateAvoidanceZone } from './edit-create-avoidance-zone';
import { Features } from 'harmony-constants';

export const avoidanceZoneStrokeColor = 'rgba(255, 125, 0, 1)';
export const globalAvoidanceZoneStrokeColor = 'rgba(255, 0, 0, 1)';
const avoidanceZoneDeactivatedStrokeColor = 'rgba(202, 202, 202, 1)';
const globalAvoidanceZoneDeactivatedStrokeColor = 'rgba(255, 255, 255, 1)';

export const avoidanceZoneColor = 'rgba(255, 125, 0, 0.2)';
export const globalAvoidanceZoneColor = 'rgba(255, 0, 0, 0.2)';
const avoidanceZoneDeactivatedColor = 'rgba(202, 202, 202, 0.2)';
const globalAvoidanceZoneDeactivatedColor = 'rgba(255, 255, 255, 0.2)';

export const AvoidanceZoneManagement = () => {
    const companyId = useCompanyId();
    const { user } = useUser();
    const userUnits = user.contact.units || user.organization.units;
    const canModifyGlobalAvoidanceZones = usePermissions(VIEW_ALL_COMPANIES);
    const canEditAvoidanceZones = usePermissions(EDIT_AVOIDANCE_ZONES);
    const canCreateAvoidanceZones = usePermissions(CREATE_AVOIDANCE_ZONES);
    const hasStandardRoutes = useFeatures(Features.StandardRoutes);

    const mapRef = React.createRef();
    const [addDialogOpen, setAddDialogOpen] = React.useState(false);
    const [selectedAvoidanceZone, setSelectedAvoidanceZone] = React.useState(null);
    const [searchText, setSearchText] = React.useState('');

    const { data: avoidanceZones = [], isLoading } = useStaticQuery(QueryKeys.avoidanceZones);
    const { data: standardRoutes = [] } = useOrganizationQuery(OrgQueryKeys.standardRoutes, { enabled: hasStandardRoutes });
    const { data: organization, isLoading: isLoadingOrg } = useOrganizationQuery('');
    const { mutate: addAvoidanceZone, isLoading: isCreating } = useAddAvoidanceZoneMutation(companyId);
    const { mutate: editAvoidanceZone, isLoading: isUpdating } = useEditAvoidanceZoneMutation(companyId);

    const onEdit = (avoidanceZone) => {
        setSelectedAvoidanceZone(avoidanceZone);

        openModal(false);
    };

    const onSelect = (avoidanceZone) => {
        setSelectedAvoidanceZone(avoidanceZone);

        const lat = avoidanceZone.geoLocation.latitude;
        const lng = avoidanceZone.geoLocation.longitude;

        mapRef.current.centerMap(lat, lng);
    };

    const onSubmit = (avoidanceZone) => {
        if (selectedAvoidanceZone) {
            editAvoidanceZone({ avoidanceZone });
        } else {
            addAvoidanceZone({ avoidanceZone });
        }

        setAddDialogOpen(false);
    };

    const openModal = (isCreatingNew) => {
        if (isCreatingNew) {
            setSelectedAvoidanceZone(null);
        }

        setAddDialogOpen(true);
    };

    const filterByCurrentOrganization = (avoidanceZone) => !avoidanceZone.organizationId || avoidanceZone.organizationId === companyId;
    const filterBySearchText = (avoidanceZone) => !searchText || avoidanceZone.name.toLowerCase().includes(searchText.toLowerCase());

    const renderAvoidanceZone = (avoidanceZone, i) => {
        let zoneColor, zoneStroke = '';
        if (avoidanceZone.organizationId) {
            if (avoidanceZone.deactivatedAt) {
                zoneColor = avoidanceZoneDeactivatedColor;
                zoneStroke = avoidanceZoneDeactivatedStrokeColor;
            } else {
                zoneColor = avoidanceZoneColor;
                zoneStroke = avoidanceZoneStrokeColor;
            }
        } else {
            if (avoidanceZone.deactivatedAt) {
                zoneColor = globalAvoidanceZoneDeactivatedColor;
                zoneStroke = globalAvoidanceZoneDeactivatedStrokeColor;
            } else {
                zoneColor = globalAvoidanceZoneColor;
                zoneStroke = globalAvoidanceZoneStrokeColor;
            }
        }

        return (
            <HereMapRectangle
                key={i}
                pointA={{
                    lat: avoidanceZone.geoLocation.bbox.topLeft.latitude,
                    lng: avoidanceZone.geoLocation.bbox.topLeft.longitude
                }}
                pointB={{
                    lat: avoidanceZone.geoLocation.bbox.bottomRight.latitude,
                    lng: avoidanceZone.geoLocation.bbox.bottomRight.longitude
                }}
                type='avoidance'
                strokeColor={zoneStroke}
                fillColor={zoneColor}
                onClick={() => setSelectedAvoidanceZone(avoidanceZone)}
                changeable={false}>
                <div>
                    <span className='map-tooltip-label-name'>{avoidanceZone.name}</span>
                    <span className='map-tooltip-label-desc'>{avoidanceZone.description}</span>
                </div>
            </HereMapRectangle>
        );
    };

    const standardRouteLines = standardRoutes.filter(route => !route.deletedAt).map(route =>
        <StandardRouteLines
            key={route.id}
            flexiblePolyline={route.flexiblePolyline}
        />
    );

    return (
        <>
            <AdminBreadCrumbHeader/>
            <Content style={{ height: '100%', overflow: 'hidden' }}>
                <Paper className='avoidance-zone-management'>
                    <div className='avoidance-zone-list-container'>
                        {canCreateAvoidanceZones &&
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => openModal(true)}
                                sx={{ margin: '0 0 1em 0' }}
                            >
                                {L.createNew()}
                            </Button>
                        }
                        <SearchBar text={searchText} setText={setSearchText}/>
                        <br />
                        <AvoidanceZoneList
                            isLoading={isLoading || isCreating || isUpdating}
                            avoidanceZones={avoidanceZones.filter(filterByCurrentOrganization).filter(filterBySearchText)}
                            canEdit={canEditAvoidanceZones}
                            canModifyGlobalAvoidanceZones={canModifyGlobalAvoidanceZones}
                            companyId={companyId}
                            onEdit={onEdit}
                            onSelect={onSelect}
                            searchText={searchText}
                            selectedAvoidanceZone={selectedAvoidanceZone}
                        />
                    </div>
                    {!isLoadingOrg &&
                        <HereMap id='avoidance-zone-management-map' ref={mapRef}
                            centerPosition={{ lat: organization?.latitude, lng: organization?.longitude }}
                        >
                            {avoidanceZones.filter(filterByCurrentOrganization).filter(filterBySearchText).map(renderAvoidanceZone)}
                            {standardRouteLines}
                        </HereMap>
                    }
                    <ModalDialog className='avoidance-zone-modal'
                        open={addDialogOpen}
                        onClose={() => setAddDialogOpen(false)}
                        title={selectedAvoidanceZone ? L.editZone() : L.addZone()}
                    >
                        <EditCreateAvoidanceZone
                            existingAvoidanceZone={selectedAvoidanceZone}
                            canModifyGlobalAvoidanceZones={canModifyGlobalAvoidanceZones}
                            centerPosition={{ lat: organization?.latitude, lng: organization?.longitude }}
                            companyId={companyId}
                            onSubmit={onSubmit}
                            userUnits={userUnits}
                            standardRouteLines={standardRouteLines}
                        />
                    </ModalDialog>
                </Paper>
            </Content>
        </>
    );
};

import { createColumnHelper } from '@tanstack/react-table';
import { L } from 'harmony-language';
import React from 'react';
import { SiteIssuesReportColumns } from '../report-types';

export const useSiteIssuesColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<SiteIssuesReportColumns>();

    const columns = React.useMemo(() => {
        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
            ]
        };

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('site', {
                header: () => L.site(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('issue', {
                header: () => L.issue(),
                // @ts-ignore
                cell: info => L[info.getValue()](),
            }),
            columnHelper.accessor('notes', {
                header: () => L.notes(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('created_at', {
                header: () => L.createdAt(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('created_by', {
                header: () => L.createdBy(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('resolved_at', {
                header: () => L.resolvedAt(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('resolved_by', {
                header: () => L.resolvedBy(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('resolution_notes', {
                header: () => L.resolutionNotes(),
                cell: info => info.getValue(),
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, []);

    return columns;
}

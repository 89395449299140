import { L } from 'harmony-language';
import { OrderStatus, Stop, StopType } from '../types';

export const WASH_OPTIONS = () => ({
    None: {
        key: null,
        value: L.none(),
        bulkDisplay: L.washBulkNone(),
        label: L.washLabelNone()
    },
    Before: {
        key: 'Before',
        value: L.before(),
        bulkDisplay: L.washBulkBefore(),
        label: L.washLabelBefore()
    },
    After: {
        key: 'After',
        value: L.after(),
        bulkDisplay: L.washBulkAfter(),
        label: L.washLabelAfter()
    },
    Both: {
        key: 'Both',
        value: L.both(),
        bulkDisplay: L.washBulkBoth(),
        label: L.washLabelBoth()
    }
});

export const STOP_TYPES = () => ({
    Origin: {
        key: 'Origin',
        label: L.origin(),
        durationLabel: L.loadDuration(),
        locationCategory: SITE_CATEGORIES.SITE
    },
    Destination: {
        key: 'Destination',
        label: L.destination(),
        durationLabel: L.unloadDuration(),
        locationCategory: SITE_CATEGORIES.SITE
    },
    Weigh: {
        key: 'Weigh',
        label: L.weigh(),
        durationLabel: L.weighDuration(),
        locationCategory: SITE_CATEGORIES.SITE
    },
    Waypoint: {
        key: 'Waypoint',
        label: L.waypoint(),
        durationLabel: L.waypointDuration(),
        locationCategory: SITE_CATEGORIES.SITE
    },
    Wash: {
        key: 'Wash',
        label: L.wash(),
        durationLabel: L.wash(),
        locationCategory: SITE_CATEGORIES.WASH,
    }
});
export const isCargoStop = (stop: Stop) => {
    // stops that do not involve loading or unloading cargo
    return ![STOP_TYPES().Weigh.key, STOP_TYPES().Waypoint.key].some(x => x === stop.type);
};
export const isCargoStopType = (stopType: StopType) => {
    // stops that do not involve loading or unloading cargo
    return ![STOP_TYPES().Weigh.key, STOP_TYPES().Waypoint.key].some(x => x === stopType);
};

export const STOP_STATUSES = () => ({
    'Not Started': { key: 'Not Started', label: L.notStarted(), color: '#000000' },
    Enroute: { key: 'Enroute', label: L.enroute(), color: '#dd7f16' },
    Arrived: { key: 'Arrived', label: L.arrived(), color: 'limegreen' },
    Complete: { key: 'Complete', label: L.complete(), color: 'green' },
});

export const ORDER_STATUSES = (): Record<OrderStatus, {
    key: OrderStatus;
    label: string;
}> => ({
    Open: {key: 'Open', label: L.open()},
    Assigned: {key: 'Assigned', label: L.assigned()},
    'In Transit': {key: 'In Transit', label: L.inTransit()},
    Delivered: {key: 'Delivered', label: L.delivered()},
    Merged: {key: 'Merged', label: L.merged()}
});

export const SITE_CATEGORIES = {
    SITE: 'Site',
    WASH: 'Wash',
};

export const Shipper = {id: 1, role: 'Shipper'};
export const Carrier = {id: 2, role: 'Carrier'};
export const Driver = {id: 3, role: 'Driver'};
export const Receiver = {id: 4, role: 'Receiver'};
export const Admin = {id: 5, role: 'Admin'};
export const SiteManager = {id: 7, role: 'SiteManager'};
export const LoadingTeamMember = {id: 8, role: 'LoadingTeamMember'};

export const AllRoles = [
    Shipper,
    Carrier,
    Driver,
    Receiver,
    Admin,
    SiteManager,
    LoadingTeamMember
];

const DynamicTranslate = (dynamicKey: any) => {
    return L[dynamicKey as keyof typeof L]();
}

type Entity = { id: number };
export const getTransUserRole = (role: Entity) => {
    return {...role, label: DynamicTranslate(`roleValue${role.id}`)};
};

export const getTransCargoType = (cargoType: Entity) => {
    return {...cargoType, label: DynamicTranslate(`cargoValue${cargoType.id}`)};
};
export const getTransCargoTypeLabel = (id: number) => {
    return DynamicTranslate(`cargoValue${id}`);
};

export const getTransCargoCategoryLabel = (category: string) => {
    return DynamicTranslate(`cargoCategory${category}`);
};

export const getTransCargoPerCompartment = (cargoTypeId: number) => {
    if(cargoTypeId){
        return DynamicTranslate(`cargoPerCompartmentLabel${cargoTypeId}`);
    }
    return L.cargoPerCompartment();
};


export const getTransLocationType = (locationType: Entity) => {
    return {...locationType, label: DynamicTranslate(`locationTypeValue${locationType.id}`)};
};

export const getTransLocationTypeLabel = (id?: number) => id ? DynamicTranslate(`locationTypeValue${id}`) : '';

export const getTransLocationCategoryLabel = (category?: string) => category ? DynamicTranslate(`locationCategory${category}`) : '';

export const getTransResourceTypeLabel = (type?: string) => type ? DynamicTranslate(type) : '';

export const DAYSOFWEEK_BITMASK = {
    Sunday: 64,
    Monday: 32,
    Tuesday: 16,
    Wednesday: 8,
    Thursday: 4,
    Friday: 2,
    Saturday: 1
};

export const CHECKLIST_TYPES = {
    Inspection: 'Inspection',
    Maintenance: 'Maintenance'
};

export const CHECKLIST_RESPONSE_BITMASK = {
    Unspecified: 1,
    OK: 2,
    Minor: 4,
    Major: 8,
};

export const SalmonellaRanks = ['negative', 'suspicious', 'mildPositive', 'highPositive'];

export const MAP_LINE_Z_INDEX = {
    ActualRoute: 10,
    StandardRoute: 5,
    CalculatedRoute: 0,
}
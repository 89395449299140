import React from 'react';
import { L } from 'harmony-language';
import Typography from '@mui/material/Typography';
import { HereMap } from '../here-maps/here-map';
import { ContentHeader } from '../shared/containers/content-header';
import '../../scss/here-map.scss';
import '../../scss/live-map.scss';
import { HereMapRectangle } from '../here-maps/here-map-rectangle';
import { OrgQueryKeys, QueryKeys } from '../../api/config';
import { type AvoidanceZone, type DeviceLocation, type Driver, type Organization, type OrganizationLocation } from '../../types';
import { LiveMapList } from './live-map-list';
import { LiveMapLoadDetails } from './live-map-load-details';
import { useConnectedDeviceLocations } from './utils/use-connected-device-locations';
import { DeviceLocationMarker } from './markers/device-location-marker';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { SiteLocator } from './components/site-locator';
import { HereMapRefObject } from '../here-maps/here-map';
import { useStaticQuery } from '../../api/queries/use-static-query';
import { DriverFilter } from './components/driver-filter';
import { useUpdateUserPreferences } from '../shared/hooks/use-update-user-preferences';
import { HereMapRoute } from '../here-maps/here-map-route';
import { usePastLoadLocations } from './utils/use-past-load-locations';
import { HereMapHeading } from '../here-maps/here-map-heading';
import { styled } from '@mui/material';
import { useOrgLocationColorProxy } from '../here-maps/use-org-location-color-proxy';
import { AvoidanceZoneBox } from '../here-maps/avoidance-zone-box';

interface LiveMapRectangleProps {
    id: number;
    name: string;
    description: string | null | undefined;
    isAvoidZone?: boolean | undefined;
    locationTypeId?: number;
    bbox: {
        topLeft: {
            latitude: number;
            longitude: number;
        };
        bottomRight: {
            latitude: number;
            longitude: number;
        }
    }
}

const LiveMapRectangle = (props: LiveMapRectangleProps) => {
    const { id, name, description, locationTypeId = -1, bbox } = props;

    return (
        <HereMapRectangle
            key={id}
            pointA={{ lat: bbox.topLeft.latitude, lng: bbox.topLeft.longitude }}
            pointB={{ lat: bbox.bottomRight.latitude, lng: bbox.bottomRight.longitude }}
            strokeColor={useOrgLocationColorProxy()[locationTypeId].stroke}
            fillColor={useOrgLocationColorProxy()[locationTypeId].fill}
            type='avoidance' //this should probably be renamed, type decides if when you hit the recenter map button if the rectangle should be included in the recenter math
            changeable={false}
        >
            <div>
                <span className='map-tooltip-label-name'>{name}</span>
                <span className='map-tooltip-label-desc'>{description}</span>
            </div>
        </HereMapRectangle>
    );
}

const LiveMapUtilsContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
    '& > :not(:last-child)': {
        marginRight: '16px'
    }
});

export const LiveMap = () => {
    const mapRef = React.useRef<HereMapRefObject>(null);
    const [selectedDeviceLocationId, setSelectedDeviceLocationId] = React.useState<number | null>(null);
    const [selectedOrgLocation, setSelectedOrgLocation] = React.useState<OrganizationLocation | null>(null);
    const { data: avoidanceZones = [] } = useStaticQuery<AvoidanceZone[]>(QueryKeys.avoidanceZones);
    const { data: organization, isLoading } = useOrganizationQuery<Organization>('');
    const { latestDeviceLocations } = useConnectedDeviceLocations();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const selectedDeviceLocation = latestDeviceLocations.find(x => x.deviceId === selectedDeviceLocationId);
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { preferences } = useUpdateUserPreferences();
    const [removedDrivers, setRemovedDrivers] = React.useState<number[]>(preferences?.removedDrivers || []);

    const pastLoadLocations = usePastLoadLocations(selectedDeviceLocation);
    const mappedRouteDeviceLocations = React.useMemo(() => {
        return pastLoadLocations.filter(x => x.accuracy && x.accuracy < 100).map(location => [location.latitude, location.longitude, null]);
    }, [pastLoadLocations]);

    const filteredDeviceLocations = React.useMemo(() => {
        return latestDeviceLocations.filter(x => !removedDrivers.includes(x.userId));
    }, [latestDeviceLocations, removedDrivers]);

    React.useEffect(() => {
        if (selectedOrgLocation && selectedOrgLocation.customGeofence) {
            mapRef.current?.centerMap(selectedOrgLocation.customGeofence.latitude, selectedOrgLocation.customGeofence.longitude, 14);
        }
    }, [selectedOrgLocation]);

    const toggleSelected = (deviceLocation: DeviceLocation) => {
        if (deviceLocation.deviceId === selectedDeviceLocationId) {
            setSelectedDeviceLocationId(null);
        } else {
            mapRef.current?.centerMap(deviceLocation.latitude, deviceLocation.longitude, 14);
            setSelectedDeviceLocationId(deviceLocation.deviceId);
        }
    }

    const toggleSelectedOrgLocation = React.useMemo(() => {
        return (orgLoc: OrganizationLocation | null) => {
            setSelectedOrgLocation(orgLoc);
        };
    }, []);

    const avoidZones = React.useMemo(() => {
        return avoidanceZones.filter(x => !x.deactivatedAt).map((avoidanceZone, i) =>
            <AvoidanceZoneBox key={i} avoidanceZone={avoidanceZone} />
        );
    }, [avoidanceZones]);

    const orgLocations = React.useMemo(() => {
        return organizationLocations
                    .filter(x => x.enabled)
                    .map((orgLocation) =>
                        orgLocation.customGeofence && orgLocation.customGeofence.bbox &&
                            <LiveMapRectangle
                                key={orgLocation.id}
                                id={orgLocation.id}
                                name={orgLocation.name}
                                description={orgLocation.description}
                                locationTypeId={orgLocation.organizationLocationTypeId}
                                bbox={orgLocation.customGeofence.bbox}
                            />
        );
    }, [organizationLocations]);

    return (
        <>
            <ContentHeader>
                <Typography variant='h5'>{L.liveMap()}</Typography>
                <LiveMapUtilsContainer>
                    <DriverFilter
                        drivers={drivers}
                        removedDrivers={removedDrivers}
                        setRemovedDrivers={setRemovedDrivers} />
                    <SiteLocator
                        sites={organizationLocations}
                        onChange={toggleSelectedOrgLocation} />
                </LiveMapUtilsContainer>
            </ContentHeader>
            <div className={'live-map-content'}>
                <div className='live-map-sidebar'>
                    {selectedDeviceLocation?.deviceId
                        ?
                        <LiveMapLoadDetails
                            deviceLocation={selectedDeviceLocation}
                            onClose={() => setSelectedDeviceLocationId(null)} />
                        :
                        <LiveMapList deviceLocations={filteredDeviceLocations} onClick={(x) => toggleSelected(x)} selectedDevice={selectedDeviceLocation} />
                    }
                </div>
                <div className='here-map-container'>
                    {isLoading || !organization ? null : <HereMap ref={mapRef}
                        controlOptions={selectedDeviceLocation ? { showMobileHeading: true } : undefined}
                        defaultZoom={8}
                        centerPosition={{ lat: organization.latitude, lng: organization.longitude }}>
                        {avoidZones}
                        {orgLocations}
                        {filteredDeviceLocations?.map(deviceLocation =>
                            <DeviceLocationMarker
                                deviceLocation={deviceLocation}
                                isSelected={selectedDeviceLocationId === deviceLocation.deviceId}
                                key={deviceLocation.deviceId}
                                onClick={() => toggleSelected(deviceLocation)}
                            />
                        )}
                        {mappedRouteDeviceLocations.length > 1 &&
                            <HereMapRoute calculateRouteFromWaypoints={false}
                                waypoints={mappedRouteDeviceLocations} />
                        }
                        {pastLoadLocations.map((location, i) => {
                            return <HereMapHeading
                                key={i}
                                location={location}
                                type={'mobile'} />;
                        })}
                    </HereMap>}
                </div>
            </div>
        </>
    );
};

import { Grid } from '@mui/material';
import React from 'react';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { STOP_TYPES } from '../../../constants/constants';
import { L } from 'harmony-language';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { Customer } from '../../../types';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';

interface OnTimeFiltersProps {
    isAdmin: boolean;
    stopTypes: string[];
    setStopTypes: (s: string[]) => void;
    cargoTypeIds: number[];
    setCargoTypeIds: (n: number[]) => void;
    customerIds: number[];
    setCustomerIds: (n: number[]) => void;
}

export const OnTimeFilters: React.FC<OnTimeFiltersProps> = (props) => {
    const { isAdmin, stopTypes, setStopTypes, cargoTypeIds, setCargoTypeIds, customerIds, setCustomerIds } = props;
    const { cargoTypeList } = useCargoTypes();
    const { data: customers = [] } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers, { enabled: !isAdmin });
    
    const stopTypesMultiItems = Object.values(STOP_TYPES()).filter(t => t.key !== 'Wash').map(type => ({
        id: type.key,
        value: type.label,
    }));
    const customersMultiItems = customers.map((customer) => ({
        id: customer.id,
        value: customer.customerName
    }));
    const cargoTypesMultiItems = cargoTypeList.map((cargoType) => ({
        id: cargoType.id,
        value: cargoType.label
    }));

    return (
        <Grid container sx={{ width: '20rem' }}>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={stopTypes}
                    setSelectedIds={setStopTypes}
                    items={stopTypesMultiItems}
                    label={L.stopTypes()}
                />
            </Grid>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={cargoTypeIds}
                    setSelectedIds={setCargoTypeIds}
                    items={cargoTypesMultiItems}
                    label={L.stopCargoTypes()}
                />
            </Grid>
            {!isAdmin && <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={customerIds}
                    setSelectedIds={setCustomerIds}
                    items={customersMultiItems}
                    label={L.customers()}
                />
            </Grid>}
        </Grid>
    );
};

import React from "react";
import { AvoidanceZone } from "../../types";
import { HereMapRectangle } from "./here-map-rectangle";

export const AvoidanceZoneBox = ({avoidanceZone}: {
    avoidanceZone: AvoidanceZone,
}) => (
    <HereMapRectangle
        pointA={{ lat: avoidanceZone.boundingBox.topLeft.latitude, lng: avoidanceZone.boundingBox.topLeft.longitude }}
        pointB={{ lat: avoidanceZone.boundingBox.bottomRight.latitude, lng: avoidanceZone.boundingBox.bottomRight.longitude }}
        strokeColor='rgba(255, 0, 0, 1)'
        fillColor='rgba(255, 0, 0, 0.2)'
        type='avoidance'
        changeable={false}
    >
        <div>
            <span className='map-tooltip-label-name'>{avoidanceZone.name}</span>
            <span className='map-tooltip-label-desc'>{avoidanceZone.description}</span>
        </div>
    </HereMapRectangle>
)
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemText, styled } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';
import { ORDER_STATUSES } from '../../../constants/constants';
import { AgisticsColors } from '../../../styles/agistics-colors';
import { type Load, type Stop } from '../../../types';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { ManuallyCompleteStopItem, StopRow } from './manually-complete-stop-item';

const OrderRow = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
    ...(!open && {
        display: 'grid',
        alignItems: 'center',
        borderTop: '1px solid #e0e0e0',
        gridAutoColumns: '5vw 10vw 10vw 2vw',
        gridAutoFlow: 'column',
    }),
}));

interface ManuallyCompleteOrderItemProps {
    order: Load;
    handleChange: (id: number, s: Stop) => void;
}

export const ManuallyCompleteOrderItem: React.FC<ManuallyCompleteOrderItemProps> = (props) => {
    const { order, handleChange } = props;
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <OrderRow open={open} onClick={handleClick} style={{backgroundColor: AgisticsColors.lightGray}}>
                <ListItemText primary={order.id}/>
                <ListItemText primary={ORDER_STATUSES()[order.status].label}/>
                <ListItemText primary={localDateTimeDisplay(order.date)}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </OrderRow>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List sx={{ paddingLeft: '2rem' }}>
                    <StopRow>
                        <ListItemText primary='' />
                        <ListItemText primary={L.stopType()} />
                        <ListItemText primary={L.status()} />
                        <ListItemText primary={L.location()} />
                        <ListItemText primary={L.distance()} />
                        <ListItemText primary={L.orderNumber()} />
                        <ListItemText primary={L.time()} />
                        <ListItemText primary={L.notify()} />
                    </StopRow>
                    {order.stops.map((stop, i) => {
                        const stopLocationId = stop.organizationLocationId
                        const previousLocationId = order.stops.find(s => s.sequence === stop.sequence - 1)?.organizationLocationId;
                        const travelTimeKey = previousLocationId ? `${previousLocationId},${stopLocationId}` : undefined;

                        return (
                            <ManuallyCompleteStopItem key={i} stop={stop} travelTimeKey={travelTimeKey} handleChange={handleChange} />
                        )
                    })}
                </List>
            </Collapse>
        </>
    );
}
import { Button } from '@mui/material';
import { ColumnSort } from '@tanstack/react-table';
import React from 'react';
import { localNow } from '../../../utils/date-time-utils';
import { ReportBase } from '../report-base';
import { ReportFilters } from '../report-filters';
import { type OnTimeReportColumns, type ReportProps } from '../report-types';
import { getLateStopsUrlAndParams, getOnTimeUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { useOnTimeColumns } from './on-time-columns';
import { OnTimeFilters } from './on-time-filters';

export enum OnTimeReportMode {
    'day' = 'day',
    'week' = 'week',
    'month' = 'month',
}

const defaultSort: ColumnSort = {
    id: 'customer_name',
    desc: false,
}

export const OnTimeReport: React.FC<ReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().weekday(0).startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().weekday(6).endOf('day').toISOString());
    const [mode, setMode] = React.useState<OnTimeReportMode>(OnTimeReportMode.week);
    const [stopTypes, setStopTypes] = React.useState<string[]>([]);
    const [customerIds, setCustomerIds] = React.useState<number[]>([]);
    const [cargoTypeIds, setCargoTypeIds] = React.useState<number[]>([]);
    const columns = useOnTimeColumns(isAdmin);
    const { reportUrl: onTimeUrl, reportParams: onTimeParams } = getOnTimeUrlAndParams(isAdmin, organizationId, startDate, endDate, mode, stopTypes, customerIds, cargoTypeIds);
    const { reportUrl: lateStopsUrl, reportParams: lateStopsParams } = getLateStopsUrlAndParams(isAdmin, organizationId, startDate, endDate, stopTypes, customerIds, cargoTypeIds);
    const { data, runReport, table, isLoading } = useReports<OnTimeReportColumns>(defaultSort, columns, onTimeUrl, onTimeParams, false);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}
                mode={mode}
                setMode={setMode}
            >
                <OnTimeFilters
                    isAdmin={isAdmin}
                    stopTypes={stopTypes}
                    setStopTypes={setStopTypes}
                    customerIds={customerIds}
                    setCustomerIds={setCustomerIds}
                    cargoTypeIds={cargoTypeIds}
                    setCargoTypeIds={setCargoTypeIds}
                />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
                confined={true}
                additionalCsvButton={
                    <Button
                        size='small'
                        variant='outlined'
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                            lateStopsParams.set('csv', 'true');
                            window.location.href = `${lateStopsUrl.toString()}?${lateStopsParams.toString()}`;
                        }}
                        disabled={!table.getRowCount()}
                    >
                        {`${'Late Stops'} CSV`}
                    </Button>
                }
            />
        </>
    );
};

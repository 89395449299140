import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { CustomerTier, Features } from 'harmony-constants';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import React, { FormEvent } from 'react';
import { DynamicQueryKeys, OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { type ChecklistTemplate, type EditCreateTrailerType, type Device, type KeysWithValsOfType, type Trailer } from '../../../../types';
import { kgToPounds, poundsToKg } from '../../../../utils/unit-conversion';
import { ResourceEditCreateContainer } from '../../../shared/containers/resource-edit-create-container';
import { CheckboxContract } from '../../../shared/inputs/checkbox-contract';
import { CheckboxEnabled } from '../../../shared/inputs/checkbox-enabled';
import { Select } from '../../../shared/inputs/select';
import { useFeatures, useTier } from '../../../user/selectors/use-permissions';
import { EditTrailerCargoType } from './edit-trailer-cargo-type';
import { EquipmentWeightInput } from '../../../shared/inputs/equipment-weight-input';

interface EditCreateTrailerProps {
    existingTrailer: Trailer | null;
    trailers: Trailer[];
    organizationId: number;
    devices: Device[];
    onSubmit: (t: EditCreateTrailerType) => void;
}

interface SelectDeviceMenuItem {
    id: number | null;
    label: string;
}

export const EditCreateTrailer: React.FC<EditCreateTrailerProps> = (props) => {
    const { existingTrailer, trailers, organizationId, onSubmit } = props;
    const [trailer, setTrailer] = React.useState<EditCreateTrailerType>(existingTrailer || {
        userDisplayName: '',
        organizationId: organizationId,
        vinNumber: null,
        licensePlate: null,
        maxCargoWeight: 0,
        deviceId: null,
        contract: false,
        enabled: true,
        trailerCargoTypes: [],
        weight: null,
        checklistTemplateId: null
    });
    const hasChecklistFeature = useFeatures(Features.Checklists);
    const { data: checklistTemplates } = useOrganizationQuery<ChecklistTemplate[]>(
        DynamicQueryKeys.resourceTypeChecklistTemplates('trailer'), {enabled: hasChecklistFeature}
    );
    const { data: devices } = useOrganizationQuery<Device[]>(OrgQueryKeys.devices);

    const isStandard = useTier(CustomerTier.Standard);
    const checklistTemplateSelectItems = checklistTemplates?.map(template => ({...template, label: template.name })) || [];
    const selectedChecklistTemplate = checklistTemplateSelectItems?.find(template => template.id === trailer.checklistTemplateId);

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit(trailer);
    };

    const renderTextField = (label: string, keyValue: KeysWithValsOfType<Trailer, string | null>, required = false) => {
        return (
            <TextField
                variant='standard'
                label={label}
                required={required}
                value={trailer[keyValue] || ''}
                onChange={(e) => {
                    setTrailer(prev => ({ ...prev, [keyValue]: e.target.value }));
                }}
                style={{
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    width: '100%'
                }} />
        );
    };

    const renderSmartTrailerField = () => {
        if (devices && devices.length > 0) {
            // cannot assign already assigned devices to a trailer, but should be able to see the device if editing that trailer and it is assigned a device
            const filteredDevices = devices.filter(device => {
                if (trailer.deviceId && 'id' in trailer) {
                    const trailerIsAssignedThisDevice = trailers.find(x => x.id === trailer.id && x.deviceId === device.id);
                    if (trailerIsAssignedThisDevice) {
                        return true;
                    }
                }
                const anotherTrailerHasDevice = trailers.find(trailer => trailer.deviceId === device.id);
                return !anotherTrailerHasDevice
            });

            const deviceMenuItems: SelectDeviceMenuItem[] = filteredDevices.map(device => {
                return {
                    id: device.id,
                    label: device.clientId,
                }
            });

            const deviceMenuItemsWithNone = [
                { id: null, label: L.none() },
                ...deviceMenuItems,
            ];

            return (
                <Select
                    label={L.smartTrailerSerialNumber()}
                    item={deviceMenuItemsWithNone.find(x => x.id === trailer.deviceId)}
                    list={deviceMenuItemsWithNone}
                    onChange={(item: SelectDeviceMenuItem) => {
                        setTrailer(prev => ({ ...prev, deviceId: item.id }));
                    }}
                />
            )
        }
    }

    const renderMaxCargoWeightField = () => {
        const [displayValue, setDisplayValue] = React.useState('');
        const unitsKey = getCurrentUnitSystem() === UNITS.Metric ? 'unitsMetricWeightAbbr' : 'unitsImperialWeightAbbr';
        const unitsLabel = L[unitsKey]();

        React.useEffect(() => {
            if (existingTrailer?.maxCargoWeight) {
                setDisplayValue(Math.round(getCurrentUnitSystem() === UNITS.Metric
                    ? existingTrailer.maxCargoWeight
                    : kgToPounds(existingTrailer.maxCargoWeight)).toString());
            }
        }, []);

        return (
            <TextField
                variant='standard'
                id={'max-cargo-weight'}
                label={L.maxCargoWeight()}
                required={true}
                type='number'
                value={displayValue}
                InputProps={{
                    inputProps: { min: 2 },
                    endAdornment: <InputAdornment position='end'>{unitsLabel}</InputAdornment>
                }}
                onChange={(e) => {
                    const newVal = getCurrentUnitSystem() === UNITS.Metric ? Number(e.target.value) : poundsToKg(e.target.value);
                    const displayValue = Math.round(getCurrentUnitSystem() === UNITS.Metric ? newVal : kgToPounds(newVal));
                    displayValue === 0 ? setDisplayValue('') : setDisplayValue(displayValue.toString());

                    setTrailer(prev => ({ ...prev, maxCargoWeight: newVal }));
                }}
                style={{
                    padding: '.25rem 0',
                    width: '100%'
                }} />
        );
    };

    return (
        <ResourceEditCreateContainer width='25vw'>
            <form id='edit-create-trailer-form' name='edit-create-trailer-form' onSubmit={submitForm}>
                {renderTextField(L.name(), 'userDisplayName', true)}
                {renderTextField(L.vinNumber(), 'vinNumber')}
                {renderTextField(L.licensePlate(), 'licensePlate')}
                <EquipmentWeightInput equipment={trailer} setEquipment={setTrailer} />
                {isStandard &&
                    <>
                        {renderMaxCargoWeightField()}
                        {renderSmartTrailerField()}
                        <EditTrailerCargoType trailerCargoTypes={trailer.trailerCargoTypes}
                            onChange={(newTrailerCargoTypes) => {
                                setTrailer(prev => ({ ...prev, trailerCargoTypes: newTrailerCargoTypes }));
                            }} />
                    </>
                }
                {hasChecklistFeature &&
                    <Select
                        label={L.checklistTemplate()}
                        list={checklistTemplateSelectItems}
                        item={selectedChecklistTemplate}
                        onChange={(checklistTemplate) => setTrailer(prev => ({...prev, checklistTemplateId: checklistTemplate.id}))}
                    />
                }
                <CheckboxContract
                    checked={trailer.contract}
                    onChange={() => setTrailer(prev => ({ ...prev, contract: !trailer.contract }))} />
                <CheckboxEnabled
                    checked={trailer.enabled}
                    onChange={() => setTrailer(prev => ({ ...prev, enabled: !trailer.enabled }))} />
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button type='submit'
                        title='Save'
                        variant='contained'
                        color='primary'>{existingTrailer ? L.updateTrailer() : L.createTrailer()}</Button>
                </div>
            </form>
        </ResourceEditCreateContainer>
    );
};

import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Select } from '../shared/inputs/select';
import { L } from 'harmony-language';
import { useStaticQuery } from '../../api/queries/use-static-query';
import { QueryKeys } from '../../api/config';
import { toIdLabelTuple } from '../../utils/data-mapping-utils';
import { useFeatures, usePermissions } from '../user/selectors/use-permissions';
import { EDIT_COMPANY, VIEW_ALL_COMPANIES, VIEW_SERVICE_USER_PERMISSION } from '../../permissions/permissions';
import { ContentHeader } from '../shared/containers/content-header';
import { AgisticsContext, useCompanyId } from '../../app/agistics-context';
import { Features } from 'harmony-constants';

const BreadCrumbContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const CompanyListDropDown = (props) => {
    const { currentCompanyId, onChange } = props;
    const { data: companies } = useStaticQuery(QueryKeys.companies);
    const companyList = companies?.map(toIdLabelTuple('name')) || [];

    return (
        <Select
            item={companyList.find(l => l.id === currentCompanyId)}
            list={companyList}
            onChange={onChange}
            inputProps={{
                disableUnderline: true,
                style: {
                    color: '#D32F2F',
                    fontSize: '1.5rem',
                }
            }}
            style={{
                width: 'unset',
            }}
        />
    );
};

CompanyListDropDown.propTypes = {
    currentCompanyId: PropTypes.number,
    onChange: PropTypes.func
};

export const AdminBreadCrumbHeader = (props) => {
    const { hideResourceDropDown, hideCompanyDropDown, children } = props;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const canViewCompanies = usePermissions(VIEW_ALL_COMPANIES);
    const canEditCompany = usePermissions(EDIT_COMPANY);
    const viewServiceUserPermission = usePermissions(VIEW_SERVICE_USER_PERMISSION);
    const canAccessServerAPI = useFeatures(Features.ServerAPI);
    const hasStandardRoutes = useFeatures(Features.StandardRoutes);
    const hasChecklists = useFeatures(Features.Checklists);
    const companyId = useCompanyId();
    const { updateCompanyId } = useContext(AgisticsContext);

    const pathList = [
        {
            label: L.tractors(),
            id: '/manage-company/tractors'
        },
        {
            label: L.trailers(),
            id: '/manage-company/trailers'
        },
        {
            label: L.users(),
            id: '/manage-company/users'
        },
        {
            label: L.contacts(),
            id: '/manage-company/contacts'
        },
        //removing Loading Teams menu item per Jeff for now,
        //he's OK with direct URL access temporarily
        // {
        //     label: L.loadingTeams(),
        //     id: '/manage-company/loading-teams'
        // },
        {
            label: L.locations(),
            id: '/manage-company/locations'
        },
        {
            label: L.customers(),
            id: '/manage-company/customers'
        },
        {
            label: L.avoidanceZones(),
            id: '/manage-company/avoidance-zones'
        }
    ]

    if (canAccessServerAPI && viewServiceUserPermission) {
        pathList.push({
            label: L.serviceAccounts(),
            id: '/manage-company/service-accounts'
        });
    }

    if (hasChecklists) {
        pathList.push({
            label: L.tractorChecklistTemplates(),
            id: '/manage-company/tractor-checklist-templates'
        },
        {
            label: L.trailerChecklistTemplates(),
            id: '/manage-company/trailer-checklist-templates'
        });
    }

    if (canEditCompany) {
        pathList.push({
            label: L.notifications(),
            id: '/manage-company/notification-configs',
        });
        pathList.push({
            label: L.settings(),
            id: '/manage-company/settings'
        });
    }

    if (hasStandardRoutes) {
        pathList.push({
            label: L.standardRoutes(),
            id: '/manage-company/standard-routes'
        });
    }

    const sortedPathList = pathList.sort((a,b) => a.label.localeCompare(b.label));

    return (
        <ContentHeader>
            <BreadCrumbContainer>
                <Link to={'/manage-company'} style={{ textDecoration: 'none', color: '#000000' }}>
                    <Typography variant='h5'>{L.administration()}</Typography>
                </Link>
                {(canViewCompanies && !hideCompanyDropDown) &&
                <>
                    <Typography variant='h5'>&#xa0;&#x2f;&#xa0;</Typography>
                    <CompanyListDropDown currentCompanyId={companyId} onChange={(value) => {
                        updateCompanyId(value.id)
                    }}/>
                </>
                }
                {!hideResourceDropDown &&
                <>
                    <Typography variant='h5'>&#xa0;&#x2f;&#xa0;</Typography>
                    <Select
                        item={sortedPathList.find(l => l.id === pathname)}
                        list={sortedPathList}
                        onChange={(value) => {
                            navigate(value.id);
                        }}
                        inputProps={{
                            disableUnderline: true,
                            style: {
                                fontSize: '1.5rem',
                            }
                        }}
                        style={{
                            width: 'unset',
                        }}
                    />
                </>
                }
            </BreadCrumbContainer>
            {children}
        </ContentHeader>
    );
};

AdminBreadCrumbHeader.propTypes = {
    hideResourceDropDown: PropTypes.bool,
    hideCompanyDropDown: PropTypes.bool,
    children: PropTypes.node,
};
